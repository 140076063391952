import f1 from '../assets/images/features/1.svg';
import f2 from '../assets/images/features/2.svg';
import f3 from '../assets/images/features/3.svg';
import f4 from '../assets/images/features/4.svg';
import f5 from '../assets/images/features/5.svg';
import f6 from '../assets/images/features/6.svg';
import s1 from '../assets/images/services/1.jpg';
import s2 from '../assets/images/services/2.jpg';
import s3 from '../assets/images/services/4.jpg';
import p1 from '../assets/images/projects/1.png';
import p2 from '../assets/images/projects/2.png';
import p3 from '../assets/images/projects/3.png';
import p4 from '../assets/images/projects/4.png';
import p5 from '../assets/images/projects/5.png';
import p6 from '../assets/images/projects/6.png';
import p7 from '../assets/images/projects/7.png';
import p8 from '../assets/images/projects/8.png';
import p9 from '../assets/images/projects/9.png';
import p10 from '../assets/images/projects/10.png';
import p11 from '../assets/images/projects/11.png';
import p12 from '../assets/images/projects/12.png';
import p13 from '../assets/images/projects/13.png';
import p14 from '../assets/images/projects/14.png';
import p15 from '../assets/images/projects/15.png';
import p16 from '../assets/images/projects/16.png';
import p17 from '../assets/images/projects/17.png';
import p18 from '../assets/images/projects/18.png';
import p19 from '../assets/images/projects/19.png';
import p20 from '../assets/images/projects/20.png';
import p21 from '../assets/images/projects/21.png';

import vscode from '../assets/images/resources/vscode.png';
import codepen from '../assets/images/resources/codepen.png';
import emmet from '../assets/images/resources/emmet.png';
import html5 from '../assets/images/resources/C1-round.png';
import mdn from '../assets/images/resources/mdn.png';
import cssTrick from '../assets/images/resources/css-tricks-3.png';
import canUse from '../assets/images/resources/caniuse.png';
import clippy from '../assets/images/resources/clippy.png';
import tutplus from '../assets/images/resources/tutplus.png';
import easings from '../assets/images/resources/easings.png';
import codrops from '../assets/images/resources/codrops.png';
import cssguide from '../assets/images/resources/css-guide.png';
import c1 from '../assets/images/resources/C1-round.png';
import c4 from '../assets/images/resources/C4-round.png';
import jspattern from '../assets/images/resources/js-patterns.png';
import github from '../assets/images/resources/github.png';
import keycodes from '../assets/images/resources/keycodes.jpeg';
import hubspot from '../assets/images/resources/hubspot.png';
import es6 from '../assets/images/resources/es6-compat.png';
import c3Round from '../assets/images/resources/C3-round.png';
import googleFont from '../assets/images/resources/googlefonts.png';
import fontSquirrel from '../assets/images/resources/fontsquirrel.jpeg';
import cb from '../assets/images/resources/creativebloq.jpeg';
import myFont from '../assets/images/resources/myfonts.png';
import typeGuide from '../assets/images/resources/typeguide.svg';
import openColor from '../assets/images/resources/opencolor.png';
import tailwind from '../assets/images/resources/tailwind.png';
import flatColor from '../assets/images/resources/flatuicolors.png';
import matColor from '../assets/images/resources/materialpalette.jpeg';
import colorHunt from '../assets/images/resources/colorhunt.gif';
import lolColor from '../assets/images/resources/lolcolors.svg';
import gradient from '../assets/images/resources/uigradients.png';
import paletton from '../assets/images/resources/paletton.png';
import maketint from '../assets/images/resources/maketintsandshades.png';
import to255 from '../assets/images/resources/0to255new.png';
import coolors from '../assets/images/resources/coolors.png';
import unsplash from '../assets/images/resources/unsplash.png';
import pexels from '../assets/images/resources/pexels.png';
import iso from '../assets/images/resources/isorepublic.png';
import pixaby from '../assets/images/resources/pixaby.png';
import undraw from '../assets/images/resources/undraw.png';
import drawkit from '../assets/images/resources/drawkit.png';
import uifaces from '../assets/images/resources/uifaces.png';
import random from '../assets/images/resources/randomuser.png';
import coverr from '../assets/images/resources/coverr.png';
import ionicons from '../assets/images/resources/ionicons.png';
import heroIcons from '../assets/images/resources/heroicons.png';
import phosphorIcons from '../assets/images/resources/phosphoricons.png';
import fontAwesome from '../assets/images/resources/fontawesome.png';
import iconMonstr from '../assets/images/resources/iconmonstr.png';
import icons8 from '../assets/images/resources/Icons8.png';
import icon54 from '../assets/images/resources/icon54.png';
import icomoon from '../assets/images/resources/icomoon.png';
import arrow from '../assets/images/arrow.svg';

export default {
  arrow,
  ionicons,
  heroIcons,
  phosphorIcons,
  fontAwesome,
  iconMonstr,
  icons8,
  icon54,
  icomoon,
  unsplash,
  pexels,
  iso,
  pixaby,
  undraw,
  drawkit,
  uifaces,
  random,
  coverr,
  openColor,
  tailwind,
  flatColor,
  matColor,
  colorHunt,
  lolColor,
  gradient,
  paletton,
  maketint,
  to255,
  coolors,
  f1,
  f2,
  f3,
  f4,
  f5,
  f6,
  p1,
  p2,
  p3,
  p4,
  p5,
  p6,
  p7,
  p8,
  p9,
  p10,
  p11,
  p12,
  p13,
  p14,
  p15,
  p16,
  p17,
  p18,
  p19,
  p20,
  p21,
  s1,
  s2,
  s3,
  googleFont,
  fontSquirrel,
  cb,
  myFont,
  typeGuide,
  vscode,
  codepen,
  emmet,
  mdn,
  html5,
  cssTrick,
  canUse,
  clippy,
  tutplus,
  easings,
  codrops,
  cssguide,
  jspattern,
  github,
  keycodes,
  hubspot,
  es6,
  c3Round,
  c1,
  c4,
};
