import React from "react";
import { Link } from "react-router-dom";

const Error = () => {
  return (
    <section className="relative z-10 pt-[180px] pb-[120px]">
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap -mx-4">
          <div className="w-full px-4">
            <div className="max-w-[530px] mx-auto text-center">
              <div className="mx-auto mb-9 text-center">
                <svg
                  className="w-full mx-auto text-center"
                  height="210"
                  viewBox="0 0 474 210"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.5"
                    d="M25 163.051H101.211V191H133.308V163.051H153V136.111H133.308V32H91.2871L25 136.577V163.051ZM101.831 136.111H58.8025V134.869L100.591 68.6445H101.831V136.111Z"
                    stroke="url(#paint0_linear_116:1137)"
                    stroke-width="3"
                  />
                  <path
                    opacity="0.5"
                    d="M307 133.051H383.211V161H415.308V133.051H435V106.111H415.308V2H373.287L307 106.577V133.051ZM383.831 106.111H340.803V104.869L382.591 38.6445H383.831V106.111Z"
                    stroke="url(#paint1_linear_116:1137)"
                    stroke-width="3"
                  />
                  <circle
                    opacity="0.8"
                    cx="227.5"
                    cy="81.5"
                    r="68.5"
                    fill="#4A6CF7"
                  />
                  <mask
                    id="mask0_116:1137"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="159"
                    y="13"
                    width="137"
                    height="137"
                  >
                    <circle
                      opacity="0.8"
                      cx="227.5"
                      cy="81.5"
                      r="68.5"
                      fill="#4A6CF7"
                    />
                  </mask>
                  <g mask="url(#mask0_116:1137)">
                    <circle
                      opacity="0.8"
                      cx="227.5"
                      cy="81.5"
                      r="68.5"
                      fill="url(#paint2_radial_116:1137)"
                    />
                    <g opacity="0.8" filter="url(#filter0_f_116:1137)">
                      <circle
                        cx="233.543"
                        cy="49.2645"
                        r="28.2059"
                        fill="white"
                      />
                    </g>
                  </g>
                  <path
                    d="M0 182H83.5V209H150.5V178H169.5V148.5C169.5 148.5 194 165 229.5 165C265 165 294 134.5 294 134.5V152H306.5H361V178H435V152H474"
                    stroke="white"
                    stroke-opacity="0.08"
                    stroke-width="2"
                  />
                  <defs>
                    <filter
                      id="filter0_f_116:1137"
                      x="175.337"
                      y="-8.94141"
                      width="116.412"
                      height="116.412"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="BackgroundImageFix"
                        result="shape"
                      />
                      <feGaussianBlur
                        stdDeviation="15"
                        result="effect1_foregroundBlur_116:1137"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_116:1137"
                      x1="25"
                      y1="183"
                      x2="126.155"
                      y2="27.0837"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4A6CF7" stop-opacity="0" />
                      <stop offset="1" stop-color="#4A6CF7" />
                    </linearGradient>
                    <linearGradient
                      id="paint1_linear_116:1137"
                      x1="307"
                      y1="153"
                      x2="408.155"
                      y2="-2.91631"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="#4A6CF7" stop-opacity="0" />
                      <stop offset="1" stop-color="#4A6CF7" />
                    </linearGradient>
                    <radialGradient
                      id="paint2_radial_116:1137"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(227.5 81.5) rotate(90) scale(73.5368)"
                    >
                      <stop stop-opacity="0.47" />
                      <stop offset="1" stop-opacity="0" />
                    </radialGradient>
                  </defs>
                </svg>
              </div>
              <h3
                className="
                  font-bold text-opacity-100 text-[rgba(9,14,52,var(--tw-text-opacity))]
                  text-3xl
                  sm:text-4xl
                  mb-4
                "
              >
                Sorry, the page can’t be found
              </h3>
              <p
                className="
                  sm:text-lg
                  leading-6
                 mb-10
                "
              >
                The page you were looking for appears to have been moved,
                deleted or does not exist.
              </p>
              <Link to="/" className="btn">
                Back to Homepage
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="
          hidden
          sm:block
          absolute left-0 bottom-0 -z-[1]
        "
      >
        <svg
          width="406"
          height="286"
          viewBox="0 0 406 286"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <rect
              opacity="0.5"
              x="56.25"
              y="110.344"
              width="116.719"
              height="116.438"
              stroke="url(#paint0_linear_116:1140)"
            />
            <rect
              opacity="0.1"
              x="56.25"
              y="110.344"
              width="116.719"
              height="116.438"
              fill="url(#paint1_linear_116:1140)"
            />
            <path
              opacity="0.5"
              d="M172.688 110.344L229.219 51V167.601L172.688 226.781V110.344Z"
              stroke="url(#paint2_linear_116:1140)"
            />
            <path
              opacity="0.1"
              d="M172.688 110.344L229.219 51V167.601L172.688 226.781V110.344Z"
              fill="url(#paint3_linear_116:1140)"
            />
            <path
              opacity="0.5"
              d="M0 169.619L56.25 110.344V226.85L0 286.125V169.619Z"
              stroke="url(#paint4_linear_116:1140)"
            />
            <path
              opacity="0.1"
              d="M0 169.619L56.25 110.344V226.85L0 286.125V169.619Z"
              fill="url(#paint5_linear_116:1140)"
            />
            <rect
              opacity="0.5"
              x="228.938"
              y="51.2812"
              width="119.25"
              height="116.438"
              stroke="url(#paint6_linear_116:1140)"
            />
            <rect
              opacity="0.1"
              x="228.938"
              y="51.2812"
              width="119.25"
              height="116.438"
              fill="url(#paint7_linear_116:1140)"
            />
            <path
              opacity="0.5"
              d="M347.906 51.2812L405 2V110.113L347.906 167.719V51.2812Z"
              stroke="url(#paint8_linear_116:1140)"
            />
            <path
              opacity="0.1"
              d="M347.906 51.2812L405 2V110.113L347.906 167.719V51.2812Z"
              fill="url(#paint9_linear_116:1140)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_116:1140"
              x1="49.0781"
              y1="112.313"
              x2="148.922"
              y2="131.859"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_116:1140"
              x1="179.141"
              y1="209.062"
              x2="32.6026"
              y2="145.47"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_116:1140"
              x1="170.016"
              y1="125.25"
              x2="217.542"
              y2="125.507"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_116:1140"
              x1="233.578"
              y1="113.156"
              x2="146.509"
              y2="143.95"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_116:1140"
              x1="-3.45633"
              y1="113.316"
              x2="46.311"
              y2="116.426"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_116:1140"
              x1="69.8907"
              y1="189.234"
              x2="84.0124"
              y2="249.947"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_116:1140"
              x1="218.953"
              y1="157.453"
              x2="330.261"
              y2="148.369"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_116:1140"
              x1="348.187"
              y1="46.6406"
              x2="280.112"
              y2="168.552"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_116:1140"
              x1="329"
              y1="190"
              x2="369.525"
              y2="-29.8829"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_116:1140"
              x1="409"
              y1="10"
              x2="331.729"
              y2="34.2741"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
      <div className="hidden sm:block absolute top-0 right-0 -z-[1]">
        <svg
          width="406"
          height="286"
          viewBox="0 0 406 286"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g opacity="0.5">
            <rect
              opacity="0.5"
              x="57.25"
              y="108.344"
              width="116.719"
              height="116.438"
              stroke="url(#paint0_linear_116:1151)"
            />
            <rect
              opacity="0.1"
              x="57.25"
              y="108.344"
              width="116.719"
              height="116.438"
              fill="url(#paint1_linear_116:1151)"
            />
            <path
              opacity="0.5"
              d="M173.688 108.344L230.219 49V165.601L173.688 224.781V108.344Z"
              stroke="url(#paint2_linear_116:1151)"
            />
            <path
              opacity="0.1"
              d="M173.688 108.344L230.219 49V165.601L173.688 224.781V108.344Z"
              fill="url(#paint3_linear_116:1151)"
            />
            <path
              opacity="0.5"
              d="M1 167.619L57.25 108.344V224.85L1 284.125V167.619Z"
              stroke="url(#paint4_linear_116:1151)"
            />
            <path
              opacity="0.1"
              d="M1 167.619L57.25 108.344V224.85L1 284.125V167.619Z"
              fill="url(#paint5_linear_116:1151)"
            />
            <rect
              opacity="0.5"
              x="229.938"
              y="49.2812"
              width="119.25"
              height="116.438"
              stroke="url(#paint6_linear_116:1151)"
            />
            <rect
              opacity="0.1"
              x="229.938"
              y="49.2812"
              width="119.25"
              height="116.438"
              fill="url(#paint7_linear_116:1151)"
            />
            <path
              opacity="0.5"
              d="M348.906 49.2812L406 0V108.113L348.906 165.719V49.2812Z"
              stroke="url(#paint8_linear_116:1151)"
            />
            <path
              opacity="0.1"
              d="M348.906 49.2812L406 0V108.113L348.906 165.719V49.2812Z"
              fill="url(#paint9_linear_116:1151)"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_116:1151"
              x1="50.0781"
              y1="110.313"
              x2="149.922"
              y2="129.859"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_116:1151"
              x1="180.141"
              y1="207.062"
              x2="33.6026"
              y2="143.47"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_116:1151"
              x1="171.016"
              y1="123.25"
              x2="218.542"
              y2="123.507"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_116:1151"
              x1="234.578"
              y1="111.156"
              x2="147.509"
              y2="141.95"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_116:1151"
              x1="85.5"
              y1="71.5"
              x2="100.174"
              y2="270.716"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_116:1151"
              x1="70.8907"
              y1="187.234"
              x2="85.0124"
              y2="247.947"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_116:1151"
              x1="219.953"
              y1="155.453"
              x2="331.261"
              y2="146.369"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_116:1151"
              x1="349.187"
              y1="44.6406"
              x2="281.112"
              y2="166.552"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_116:1151"
              x1="394.75"
              y1="64.3284"
              x2="409.531"
              y2="110.901"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" stop-opacity="0" />
              <stop offset="1" stop-color="#4A6CF7" />
            </linearGradient>
            <linearGradient
              id="paint9_linear_116:1151"
              x1="410"
              y1="8.00001"
              x2="332.729"
              y2="32.2741"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#4A6CF7" />
              <stop offset="1" stop-color="#4A6CF7" stop-opacity="0" />
            </linearGradient>
          </defs>
        </svg>
      </div>
    </section>
  );
};

export default Error;
